import type { MeasureMapEntry, TMeasureMap } from './MeasureMap';
import MeasureMap from './MeasureMap';
import type { Duration, Onset } from './Unit';

type Offset = number | '';
type Sign = '+' | '-' | '';
type Denominator = `/${number}` | '';
export type OnsetString = `${Offset}${Sign}${number}${Denominator}`;

const decimalRegex = /^([+-]?\d+(\.\d+)?)$/; // 3.25
const shortFractionRegex = /^([+-]?\d+)\/(\d+)?$/; // 13/4
const fractionRegex = /^([+-]?\d+)([+-]\d+)\/(\d+)?$/;// 3+1/4

export interface ParsedOnsetString {
  measure: number;
  num: number;
  denum: number;
}

export interface Measure {
  measure: number;
  remainder: number;
  measureOffset: number;
  timeSignature: number;
  denominator: number;
  numerator: number;
  measureMapEntry: MeasureMapEntry;
}

interface BaseMeasure {
  numerator?: number;
  denominator?: number;
  upbeat?: number;
}

const EPSILON = 0.01;

export default class Signature {
  readonly explicit: boolean; // false for default 4/4 time signature

  private numerator: number;

  private denominator: number;

  readonly upbeat: number;

  private measureMap!: MeasureMap;

  public get timeSignature(): number {
    return this.numerator / this.denominator;
  }

  public get pretty(): string {
    if (!this.explicit) return '';

    return `${this.numerator}/${this.denominator}`;
  }

  // constructor(numerator = 0, denominator = 4, upbeat = 0) {
  constructor();

  constructor(measure: TMeasureMap | BaseMeasure);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(...args: any[]) {
    const measure: BaseMeasure = args[0] || {};

    if (Array.isArray(measure) && measure.length > 0) {
      const measureMap: TMeasureMap = measure;
      this.explicit = false;
      this.numerator = parseInt(measureMap[0].time_signature.split('/')[0], 10);
      this.denominator = parseInt(measureMap[0].time_signature.split('/')[1], 10);

      this.upbeat = measureMap[0].actual_length === measureMap[0].nominal_length
        ? 0 : measureMap[0].nominal_length;
      this.measureMap = new MeasureMap(measureMap);
    } else {
      if (!measure.numerator) measure.numerator = 0;
      if (!measure.denominator) measure.denominator = 4;
      if (!measure.upbeat) measure.upbeat = 0;

      this.explicit = (measure.numerator !== 0);
      this.numerator = this.explicit ? measure.numerator : 4;
      this.denominator = measure.denominator;
      this.upbeat = measure.upbeat;

      const actualLength = this.upbeat === 0
        ? this.numerator / (this.denominator / 4)
        : this.upbeat;
      const map: TMeasureMap = [];
      map.push({
        count: 1,
        qstamp: 0,
        number: 0,
        time_signature: `${this.numerator}/${this.denominator}`,
        nominal_length: this.numerator / (this.denominator / 4),
        actual_length: actualLength,
        start_repeat: false,
        end_repeat: false,
        next: [2],
      });
      if (this.upbeat) {
        map.push({
          count: 2,
          qstamp: actualLength,
          number: 1,
          time_signature: `${this.numerator}/${this.denominator}`,
          nominal_length: this.numerator / (this.denominator / 4),
          actual_length: this.numerator / (this.denominator / 4),
          start_repeat: false,
          end_repeat: false,
          next: [3],
        });
      }
      this.measureMap = new MeasureMap(map);
    }
  }

  // Getters for the constants of this signature object

  public get hasUpbeat(): boolean {
    return (this.upbeat > 0);
  }

  public get increment(): Duration {
    return (1 / this.denominator) as Duration;
  }

  public get beatIncrement(): Duration {
    const beat: number = this.denominator === 8 ? 3 : 1;
    return ((beat * 4) / this.denominator) as Duration;
  }

  public get measureIncrement(): Duration {
    return (4 * this.timeSignature) as Duration;
  }

  public get measureOffset(): number {
    return this.measureIncrement - this.upbeat;
  }

  public measureToOnset(value: OnsetString): Onset {
    const parsedOnset = this.splitStringFormats(value);
    const onset = this.measureMap.measureToOnset(parsedOnset.measure);
    return onset as Onset;
  }

  // Public parsing/printing functions
  // Typed and documented

  /**
   * Parse a string representing an onset (in measures) into a float (in quarters)
   * 1/4 should be always a quarter, even in 2/4, 3/4, 3/8, or 6/8 measures
   * @param {string} str - string representation such as '14', '14+1/4' or '15-1/8'
   * @return {float} float offset such as 52.0, 53.0 or 55.5
   */
  public parseOnset(value: OnsetString): Onset {
    const parsedOnset = this.splitStringFormats(value);
    const onset = this.measureMap.parse({
      ...parsedOnset,
      measure: this.explicit
        && parsedOnset.num < 0
        && this.upbeat ? parsedOnset.measure + 1 : parsedOnset.measure,
    });

    return onset as Onset;
  }

  /**
   * Parse a string representing a duration (in measures) into a float (in quarters)
   * 1/4 should be always a quarter, even in 2/4, 3/4, 3/8, or 6/8 measures
   * @param {string} str - string representation such as '1', '2+1/4'
   * @return {float} measure - positive float offset such as 8.0 or 16.5
   */
  public parseDuration(value: OnsetString): Duration {
    const duration = this.parse(this.splitStringFormats(value));

    if (duration < 0) throw new Error('NegativeDurationString');

    return duration as Duration;
  }

  /**
   * Get the human readable string representation of an onset
   * @param value a valid finite onset
   * @returns the string representation, such as 1-1/4 , 2+2/5 or 3.543
   */
  public printOnset(value: Onset, round = false): OnsetString {
    return this.print(value, false, 0 as Onset, round);
    // return this.print(value + this.measureOffset, false);
  }

  /**
   * Get the human readable string representation of a duration
   * @param value a valid non-negative duration
   * @returns the string representation, such as 3/4 , 2+2/5 or 3.543
   */
  public printDuration(value: Duration, startingOnset: Onset = 0 as Onset): OnsetString {
    return this.print(value, true, startingOnset);
  }

  /**
   * Get the number of entries in the mmap
   */
  public getMeasureNumber(): number {
    console.log('get', this.measureMap.size());
    // return this.measureMap.size();
    return 40;
  }

  /**
   * Get a measure duration
   */
  public getMeasureDuration(i: number): Duration {
    // TODO
    return this.measureIncrement;
  }

  /**
   * Get the rounded value of an onset in measure
   * Rounds up by at most 0.01
   * @param value a valid finite onset, such as -1, 0, 345.2, ...
   * @returns the rounded measure, such as 0, 1, 2 ...
   */
  public getMeasureFromOnset(value: Onset): number {
    return this.measureMap.getMeasure(value).measure;
  }

  /**
   * Get the rounded value of a duration in measure
   * Rounds up by at most 0.01
   * @param value a valid non-negative duration, such as 0, 10.23 ...
   * @returns the rounded measure, such as 0, 1, 2 ...
   */
  public getMeasureFromDuration(value: Duration): number {
    return this.measureMap.getMeasure(value).measure;
  }

  /**
   * Get the onset of the start of a measure
   */
  public getOnsetFromMeasure(measure: number): Onset {
    return this.measureMap.measureToOnset(measure);
  }

  /**
   * Get the duration of the start of a measure
   */
  public getDurationFromMeasure(measure: number): Duration {
    return this.parse({ measure, num: 0, denum: 4 }) as Duration;
  }

  // Internal functions

  private splitStringFormats(expr: string): ParsedOnsetString {
    // Matches 3 + 1 / 4
    {
      const res = expr.match(fractionRegex)?.slice(1, 4)
        .map((n) => Number.parseInt(n, 10)) ?? [];

      if (res.length === 3 && res.every(Number.isFinite)) {
        const [measure, num, denum] = res;
        return { measure, num, denum };
      }
    }

    // Matches 13 / 4
    {
      const res = expr.match(shortFractionRegex)?.slice(1, 3)
        .map((n) => Number.parseInt(n, 10)) ?? [];

      if (res.length === 2 && res.every(Number.isFinite)) {
        const [num, denum] = res;
        return { measure: 0, num, denum };
      }
    }

    // Matches 3.25
    {
      const res = expr.match(decimalRegex)?.slice(1, 2)
        .map(Number.parseFloat) ?? [];

      if (Number.isFinite(res[0])) {
        const measure = res[0];
        return { measure, num: 0, denum: 1 };
      }
    }
    throw new Error('BadFractionalString');
  }

  private parse(value: ParsedOnsetString, startingOnset: Onset = 0 as Onset): number {
    const mm = this.measureMap.getMeasure(startingOnset);
    const { measure, num, denum } = value;

    const onset = (measure * mm.timeSignature + num / denum) * 4;

    if (Number.isFinite(onset) === false) {
      throw new Error('BadFractionalString');
    }

    return onset;
  }

  private format(measure: number | string, num: number, denum: number): OnsetString {
    if (num === 0) return `${measure}` as OnsetString;
    // 1/8 format
    if (measure === 0) return `${num}/${denum}` as OnsetString;
    // 5-1/8 format
    if (num < 0) return `${measure}${num}/${denum}` as OnsetString;
    // 5+1/8 format
    return `${measure}+${num}/${denum}` as OnsetString;
  }

  private print(
    value: number,
    isDuration: boolean,
    startingOnset: Onset = 0 as Onset,
    round = false,
  ): OnsetString {
    const {
      measure, remainder, timeSignature, denominator, measureMapEntry,
    } = isDuration
      ? this.measureMap.getDuration(value, startingOnset)
      : this.measureMap.getMeasure(value);

    // The remainder, in the number of quarter notes divided by 4
    // can be >= 1.0 for non x/4 time signatures
    const quarterNotes = remainder * timeSignature;

    function aboutZero(x: number) {
      return (Math.abs(x) < EPSILON);
    }

    function aboutInt(x: number) {
      return Math.abs(Math.round(x) - x) < EPSILON;
    }

    // If the remainder is < 0.01, just use the measure
    if (aboutZero(quarterNotes)) {
      if (measureMapEntry.name && !isDuration) {
        return measureMapEntry.name as `${number}`;
      }
      return (isDuration) ? (measure - 1).toString() as `${number}` : measure.toString() as `${number}`;
    }

    // Find a denominator
    const denum = [4, 8, 16, 32, 12, 24, 48].find((denumIt) => {
      // it must be at least as large as the signature
      if (denominator > denumIt) return false;

      if (round) {
        return true;
      }
      // If quarterNotes * denum is close to an int
      // It can be written as round(quarterNotes * denum) / denum
      return aboutInt(quarterNotes * denumIt);
    });

    // If no denum was found, just write a decimal number
    if (denum === undefined) {
      return (value / denominator).toFixed(3) as `${number}`;
    }

    const num = Math.round(quarterNotes * denum);

    // Transform 1+7/8 into 2-1/8 for both onsets and durations
    const isUpbeat = (remainder > 0.74); // .74 is 3/4 - EPSILON

    // Transform 0+3/8 into 1-5/8 for onsets only
    const isAnacrusis = !isDuration && measure === 0;

    if (isUpbeat || isAnacrusis) {
      const num1 = Math.round((remainder - 1) * timeSignature * denum);
      if (measureMapEntry.name && !isDuration) {
        return this.format(
          isUpbeat ? measureMapEntry.number + 1 : measureMapEntry.number,
          num1,
          denum,
        );
      }
      return this.format(isDuration ? measure : measure + 1, num1, denum);
    }
    return this.format(
      measureMapEntry.name && !isDuration ? measureMapEntry.name : measure,
      num,
      denum,
    );
  }
}
