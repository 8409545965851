import { Analysis, FilterGroup, defaultTypes } from '@/data';
import { SortedLabels } from '@/logic/SortedLabels';
import LabelParser from './LabelParser';
import type { AnalysisFormat, LabelFormat, LayoutFormat } from './analysis-formats';
import { LineNameList } from './analysis-formats';
import { analysisLoggerTransformer } from './analysis-schemas';

function hasADefaultFilter(type: string): boolean {
  return defaultTypes.filters.map((f) => f.type).includes(type);
}

function hasAFilterIn(label: LabelFormat, filters: LayoutFormat[]) {
  return filters
    .filter((layout) => layout.filter.type === label.type || layout.filter.tag === label.tag)
    .length > 0;
}

export default class AnalysisParser extends Analysis {
  /**
   * Simplified placeholder format parsing
   */
  static parseFormat(format: AnalysisFormat, name?: string): Analysis {
    // Validate and values
    analysisLoggerTransformer(format);

    const {
      layout: filtersFormat = [],
      creationButtons = [],
      ...meta
    } = format.meta || {};

    const filtersFromLabels: Array<LayoutFormat|null> = format.labels
      .map((l) => {
        if (
          l.type
          && !hasADefaultFilter(l.type)
          && !hasAFilterIn(l, filtersFormat)
        ) {
          return {
            filter: {
              type: l.type || undefined,
              tag: l.tag || undefined,
              layers: l.layers || [],
            },
          };
        }
        return null;
      }).filter((e) => e !== null);

    // Transforms LayoutFormat array into FilterArg array
    // Flattens both style and textStyle, and garantees the presence of each property
    const filters = FilterGroup
      .fromFilters([...filtersFromLabels, ...filtersFormat].map((layout) => ({
        layers: layout?.filter.layers ?? [],
        type: layout?.filter.type ?? null,
        tag: layout?.filter.tag ?? null,
        line: layout?.style?.line ? LineNameList.indexOf(layout?.style.line) : null,
        color: layout?.style?.color ?? 'auto',
        fontStyle: layout?.textStyle?.fontStyle ?? null,
        fontWeight: layout?.textStyle?.fontWeight ?? null,
        fontSize: layout?.textStyle?.fontSize ? `${layout?.textStyle.fontSize}px` : null,
        styleType: layout?.style?.type ?? null,
        symbol: layout?.style?.symbol ?? null,
        icon: layout?.style?.icon ?? null,
      })));

    const labels = format.labels
      .map((label) => LabelParser.fromFormat(label, filters, format.signature));

    const templates = creationButtons.map(
      (label) => LabelParser.fromTemplateFormat(label, filters),
    );
    const a = new Analysis(
      labels,
      templates,
      meta as Record<string, string>,
      filters,
      format.meta?.layout,
      name || null,
    );
    return a;
  }

  static exportFormat(analysis: Analysis, sortLabels = true): AnalysisFormat {
    const labels = Object.values(analysis.labels)
      .sort(sortLabels ? SortedLabels.compare : () => 0)
      .map(LabelParser.exportFormat);

    const creationButtons = analysis.templates.length
      ? analysis.templates.map(LabelParser.exportTemplateFormat) : undefined;

    const format: AnalysisFormat = {
      labels,
      meta: {
        layout: analysis.layoutRaw as LayoutFormat[] | undefined,
        creationButtons,
        ...analysis.meta,
      },
    };
    // Remove all 'undefined' values and deep copies all info
    return JSON.parse(JSON.stringify(format));
  }
}
