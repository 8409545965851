import type { AudioFileFormat } from '@/formats/AudioParser';
import AudioParser from '@/formats/AudioParser';
import type {
  AudioImageFileFormat, Image3DFileFormat, ImageDimensions, ImageFileFormat,
} from '@/formats/SourceParser';
import SourceParser from '@/formats/SourceParser';
import StaffParser from '@/formats/StaffParser';
import type {
  AudioImageSyncFormat, AudioSyncFormat, Image3DSyncFormat, ImageSyncFormat,
  UnitCoupleFormat,
} from '@/formats/UnitParser';
import UnitParser from '@/formats/UnitParser';
import type {
  Audio, Onset, Pixel, Second, Signature, Source, Staff, Unit,
} from '@/data';
import { StaffType, SourceType } from '@/data';
import type { PieceFormat } from '@/formats/PieceParser';
import type { Image3DPageData } from '@/data/Source';
import { ClientResponse } from './ClientResponse';
import type { IClient } from './IClient';

async function loadImageDimensions(url: string): Promise<ImageDimensions> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve({
      width: image.naturalWidth as Pixel,
      height: image.naturalHeight as Pixel,
    });
    image.onerror = reject;
    image.src = url;
  });
}

/**
 * Filter out an array of settles promises and log errors
 * @param result The output array of Promise.allSettled
 * @returns All successful results
 */
function filterSettled<T>(result: PromiseSettledResult<T>[]): T[] {
  return result.map((r) => {
    if (r.status === 'fulfilled') {
      return r.value;
    }
    // eslint-disable-next-line no-console
    console.error('Could not load source', r.reason);
    return null;
  }).filter((r) => !!r) as T[];
}

/**
 * Load an image source using info from the Piece object
 * @param this the dezrann client
 * @param format the format of the image info, from the SourceFormat
 * @param id the id of the piece
 * @returns a complete Source object
 */
async function loadImageSource(
  this: IClient,
  format: ImageFileFormat,
  id: string,
): Promise<Source> {
  const url = this.url(`${this.staticRoute}/${id}`, format.positions).data;

  const imageUrl = this.url(`${this.staticRoute}/${id}`, format.image).data;

  const syncFormat = (await this.get<ImageSyncFormat>(url)).data;

  const pixelUnit = UnitParser.createImageUnit(syncFormat);

  const dimensions = await loadImageDimensions(imageUrl);

  const staves = StaffParser.parseFormat(syncFormat.staffs, dimensions.height);

  return SourceParser
    .fromFormat(format, dimensions, pixelUnit, staves, imageUrl, undefined, [{
      height: dimensions.height,
      rows: [{
        staves,
        y: staves[0].top,
        height: staves[0].height,
        end: dimensions.width,
        start: 0 as Pixel,
      }],
    }]);
}

async function loadImage3DSource(
  this: IClient,
  format: Image3DFileFormat,
  id: string,
): Promise<Source> {
  const url = `${this.baseUrl}${this.staticRoute}/${id}/${format.positions}`;
  const imageUrl = this.url(id, format.image).data;
  const syncFormat = (await this.get<Image3DSyncFormat>(url)).data;
  const pixel3DUnit = UnitParser.createImage3DUnit(syncFormat);
  let resourcesUrl = '';
  if (this.isDirectory) {
    resourcesUrl = this.url(`${id}/`).data;
  } else if (syncFormat.pages[0].image.endsWith('.svg')) {
    resourcesUrl = `${this.baseUrl}resources/${id}/sources/images/score/`;
  } else {
    resourcesUrl = `${this.baseUrl}resources/${id}/sources/images/scan/`;
  }
  const dimensions = await loadImageDimensions(`${resourcesUrl}${syncFormat.pages[0].image}`);
  const staves = StaffParser.parseFormat([
    { top: 0, bottom: dimensions.height * (syncFormat.pages.length) },
  ], dimensions.height * (syncFormat.pages.length) as Pixel);

  const pages: Array<Image3DPageData> = [];
  syncFormat.pages.forEach((page, pageIndex) => {
    pages.push({
      image: `${resourcesUrl}${page.image}`,
      height: dimensions.height,
      rows: [],
    });
    page.rows.forEach((row) => {
      const parsedStaves: Staff[] = row.staves?.map((s, index) => ({
        top: s.top as Pixel,
        bottom: s.bottom as Pixel,
        height: s.bottom - s.top as Pixel,
        id: index + 1,
        type: StaffType.staff,
        name: 'default',
      })) || [];
      pages[pageIndex].rows.push({
        staves: [
          {
            top: row.y as Pixel,
            bottom: (row.y + row.height) as Pixel,
            height: row.height as Pixel,
            id: 0,
            type: StaffType.line,
            name: 'all',
          },
          ...parsedStaves,
        ],
        y: row.y as Pixel,
        height: row.height as Pixel,
        end: row['end-x'] as Pixel,
        start: row['start-x'] as Pixel,
      });
    });
  });

  return SourceParser
    .fromFormat(format, dimensions, pixel3DUnit, staves, imageUrl, undefined, pages);
}

/**
 * Load an audio image source (spectrogram) using info from the Piece object
 * @param this the dezrann client
 * @param format the format of the audio image info, from the SourceFormat
 * @param secondUnit the onset-to-second unit of the parent track
 * @param id the id of the piece
 * @returns a complete Source object
 */
async function loadAudioImageSource(
  this: IClient,
  format: AudioImageFileFormat,
  track: Audio,
  id: string,
): Promise<Source> {
  const timeToPixelUrl = this.url(`${this.staticRoute}/${id}`, format.positions).data;

  const timeToPixel = (await this.get<AudioImageSyncFormat>(timeToPixelUrl)).data;

  const pixelUnit = UnitParser.createAudioImageUnit(track.secondUnit, timeToPixel);

  const imageUrl = this.url(`${this.staticRoute}/${id}`, format.image).data;

  const dimensions = await loadImageDimensions(imageUrl);

  const staves = StaffParser.parseFormat(timeToPixel.staffs, dimensions.height);

  return SourceParser.fromFormat(format, dimensions, pixelUnit, staves, imageUrl, track, [{
    height: dimensions.height,
    rows: [{
      staves,
      y: staves[0].top,
      height: staves[0].height,
      end: dimensions.width,
      start: 0 as Pixel,
    }],
  }]);
}

/**
 * Load an audio track using info from the piece object
 * @param this The dezrann Client
 * @param format the format of the audio info, from the SourceFormat
 * @param unit the onset-to-second unit of the track, pre-loaded by loadAudioSources
 * @param id of the piece
 * @returns
 */
function loadAudio(
  this: IClient,
  format: AudioFileFormat,
  secondUnit: Unit<Second>,
  id: string,
): Audio {
  let url: string | undefined;
  let ytId: string | undefined;

  // Using the object structure, load the audio id from the backend or youtube
  if ('yt-id' in format && format['yt-id']) {
    ytId = format['yt-id'];
  }
  if ('file' in format && format.file) {
    url = this.url(`${this.staticRoute}/${id}`, format.file).data;
  }

  if (!ytId && !url) {
    throw ClientResponse.fromParsingError(new Error('Invalid Audio Format: missing source'));
  }

  return AudioParser.fromFormat(
    secondUnit,
    url,
    ytId,
    format.info,
    format.contributors,
    format.refs,
    format.year,
  );
}

export interface AudioSources {
  track: Audio;
  images: Source[];
}
async function loadAudioSources(
  this: IClient,
  format: AudioFileFormat,
  id: string,
): Promise<AudioSources> {
  const audioSyncUrl = format['onset-date'];

  if (!audioSyncUrl) {
    throw ClientResponse.fromParsingError(new Error('Invalid Audio Format: no sync url'));
  }

  const onsetToTimeUrl = this.url(`${this.staticRoute}/${id}`, audioSyncUrl).data;

  const onsetToTime = (await this.get<AudioSyncFormat>(onsetToTimeUrl)).data;

  const secondUnit = UnitParser.createAudioUnit(onsetToTime);

  const track = loadAudio.bind(this)(format, secondUnit, id);

  const imagePromises = format
    ?.images
    ?.map((imageFormat) => {
      const newFormat = imageFormat;
      newFormat.license = format.license ?? '';
      newFormat.info = format.info ?? '';
      return loadAudioImageSource.bind(this)(newFormat, track, id);
    })
    ?? [];

  const imagesSettled = await Promise.allSettled(imagePromises);

  const images = filterSettled(imagesSettled);

  return { images, track };
}

export interface PieceSources {
  sources: Source[];
  tracks: Audio[];
}

export async function loadPieceSources(
  this: IClient,
  format: PieceFormat,
  path: string,
): Promise<PieceSources> {
  // Get promises for all scores
  const scoreSourcePromises: Promise<Source>[] = format
    ?.sources
    ?.images
    ?.map((imageFormat) => {
      if (imageFormat.type === SourceType.Image3D) {
        return loadImage3DSource.bind(this)(imageFormat, path);
      }
      return loadImageSource.bind(this)(imageFormat, path);
    })
    ?? [];

  // Get promises for all audio files
  const audioSourcePromises: Promise<AudioSources>[] = format
    ?.sources
    ?.audios
    ?.map((audioFormat) => loadAudioSources.bind(this)(audioFormat, path))
    ?? [];

  // Await and filter results

  const scoresSourcesSettled = await Promise.allSettled(scoreSourcePromises);
  const scoreSources = filterSettled(scoresSourcesSettled);

  const audioSourceSettled = await Promise.allSettled(audioSourcePromises);
  const audioSources = filterSettled(audioSourceSettled);

  const tracks = audioSources.map((s) => s.track);

  const audioImageSources = audioSources.map((s) => s.images).flat();

  const sources = [
    ...scoreSources,
    ...audioImageSources,
  ];

  sources.forEach((s, index) => s.setId(index));

  return { sources, tracks };
}

export function generateGridSource(
  signature: Signature,
  measureCount: number,
  measuresPerRow: number,
  breakPoints: Array<Onset>,
  pageBreakPoints: Array<Onset>,
  twoHalves: boolean,
): Source {
  const leftMargin = 100;
  const rowWidth = 1600;
  const onsetsPerRow = measuresPerRow * 4;
  const onsetWidth = rowWidth / onsetsPerRow;
  const cellHeight = 100;
  const pageMargin = 10;

  const unitPages: Image3DSyncFormat = {
    pages: [{
      image: '',
      rows: [],
    }],
  };
  const imagePages: Image3DPageData[] = [{
    height: pageMargin,
    rows: [],
  }];

  let currentPage = 0;
  let currentRow = 0;
  let currentRowInPage = 0;

  const firstCompleteMeasure = signature.hasUpbeat ? 2 : 1;
  let firstOnsetOfRow = signature.getOnsetFromMeasure(firstCompleteMeasure);
  let currentMeasureInRow = 0;
  let currentBreakpoint = 0;
  const currentPageBreakpoint = 0;
  let y = 0;

  let onsetX: UnitCoupleFormat<'onset' | 'x'> = [];

  // This loop is on measures, we do not want to break measures
  for (let m = 1; m <= measureCount; m += 1) {
    // TODO: #628, non 4/4, from mmap
    const currentOnset = signature.getOnsetFromMeasure(m);
    const currentMeasureDuration = signature.getMeasureDuration(m);

    // CHECK: should becurrentRowInPage, not currentRow?
    y = (cellHeight * currentRowInPage) + (pageMargin * currentPage);

    // Starts new page ? new row ?
    const newPage = (currentOnset >= pageBreakPoints[currentPageBreakpoint]);

    let newRow = newPage
    || (currentOnset + currentMeasureDuration - firstOnsetOfRow > onsetsPerRow); // Row is full
    // || (currentMeasureInRow >= measuresPerRow); // Row is full

    if (currentOnset >= breakPoints[currentBreakpoint]) {
      newRow = true;
      currentBreakpoint += 1;
    }

    // || Row break... #636
    const lastRow = (m === measureCount); // Flush last row;

    // Push previous row (when it exists)
    if ((newRow || lastRow) && onsetX.length) {
      unitPages.pages[currentPage].rows.push({
        'onset-x': onsetX,
        'start-x': leftMargin,
        'end-x': leftMargin + rowWidth,
        y,
        height: 0,
      });

      imagePages[currentPage].height += cellHeight;

      // Creates staves

      let topbotY = [10, 15, 20, 25, 30, 60, 90];

      if (twoHalves) topbotY = [0, 3, 50, 97, 98, 99, 100];

      const names = ['top.1', 'top.2', 'top.3', 'bot.1', 'bot.2', 'bot.3'];

      const staves = [];
      staves.push({
        id: 0,
        top: y + topbotY[0] as Pixel,
        bottom: y + topbotY[topbotY.length - 1] as Pixel,
        height: (topbotY[topbotY.length - 1] - topbotY[0]) as Pixel,
        name: 'all',
        type: StaffType.line,
      });

      for (let i = 0; i < names.length; i += 1) {
        const top = topbotY[i];
        const bottom = topbotY[i + 1];
        staves.push({
          id: i + 1,
          top: y + top as Pixel,
          bottom: y + bottom as Pixel,
          height: (bottom - top) as Pixel,
          name: names[i],
          type: StaffType.line,
        });
      }

      imagePages[currentPage].rows.push({
        start: leftMargin as Pixel,
        end: leftMargin + rowWidth as Pixel,
        y: y as Pixel,
        height: cellHeight as Pixel,
        staves,
      });
    }

    if (lastRow) break;

    if (newRow) {
      currentRow += 1;
      currentRowInPage += 1;
      currentMeasureInRow = 0;
      firstOnsetOfRow = currentOnset;
      onsetX = [];
    }

    // Push previous page
    if (newPage) {
      currentBreakpoint += 1;
      currentPage += 1;
      currentRowInPage = 0;

      unitPages.pages.push({
        image: '',
        rows: [],
      });
      imagePages.push({
        height: pageMargin,
        rows: [],
      });
    }

    // Now we push the onsets of this measure
    for (let x = 0; x <= currentMeasureDuration; x += 1) {
      onsetX.push({
        onset: currentOnset + x,
        x: leftMargin + (currentOnset + x - firstOnsetOfRow) * onsetWidth,
      });
    }
    currentMeasureInRow += 1;
  } // end loop on measures

  const source = SourceParser.fromFormat({
    image: '',
    positions: '',
    type: SourceType.Grid,
  }, {
    // TODO: What height should we put here? Combined pages? One page?
    height: y as Pixel,
    width: leftMargin + rowWidth as Pixel,
  }, UnitParser.createImage3DUnit(unitPages), [{
    id: 0, top: 0 as Pixel, bottom: 0 as Pixel, height: 0 as Pixel, name: 'all', type: StaffType.line,
  }], '', undefined, imagePages);
  return source;
}
